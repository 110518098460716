<template>
  <div>
    <CustomTableProducts
      style="max-width: fit-content; min-width: 102%;"
      :list="list"
      :pageProp="page"
      :pages="pages"
      :itemsPerPage="itemsPerPage"
      :totalItems="totalItems"
      :showFilters="true"
      :filterStatus=[]
      :showDownloadBtn="true"
      :loading="loading"
      :loadingAll="loadingAll"
      tableName="Produtos"
      @updateFetchPage="updateFetchPage"
      @paginatorHandler="paginatorHandler"
      @updateStatus="updateStatus"
      @queryForProduct="queryForProduct"
      @addProductToCart="addProductToCart"
      @sortBy="sortBy"
      @downloadPdf="downloadPdf"
    />
  </div>
</template>

<script>
import CustomTableProducts from "@/components/customTables/CustomTableProducts.vue";
import store from "@/store";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {toast} from "vue3-toastify";
import 'vue3-toastify/dist/index.css';
export default {
  name: "ClientProducts",

  components: {
    CustomTableProducts,
  },
  data() {
    return {
      list: [],
      page: 1,
      pages: 1,
      itemsPerPage: 15,
      totalItems: 0,
      query: "",
      families: [],
      status: "",
      filterstatus: [],
      loading: false,
    };
  },
  methods: {
    orderFamilies(families) {
      //remove empty ref
      families = families.filter((family) => family.ref.trim().length);
      //order by ref
      families.sort((a, b) => {
        if (a.nome < b.nome) return -1;
        if (a.nome > b.nome) return 1;
        return 0;
      });
      //remove duplicated ref
      families = families.filter((family, index) => {
        if (index === 0) return true;
        if (family.ref === families[index - 1].ref) return false;
        return true;
      });
      //ctreate array with nomes only
      this.filterstatus = families.map((family) => family.nome);

      return families;
    },
    addProductToCart(item) {
      store.commit("addProductToCartNoMap", item);
      toast.success("Artigo adicionado", {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: 'slide',
        theme: 'colored',
        type: 'success',
      });
    },
    loadFamilies() {
      ApiService.get("products/families", "?PageSize=99999").then((res) => {
        this.families = this.orderFamilies(res.data.data);
      });
    },
    getProducts(orderBy = 'dtmCompra', orderAsc = false) {
      let queryString = `?pageNumber=${this.page}&pageSize=${this.itemsPerPage}&intCodEntidade=${store.getters.currentUser.numcl}&orderBy=${orderBy}&orderAsc=${orderAsc}`;
      
      if (this.query) {
        queryString += `&strCodArtigo=${this.query}&strAbreviatura=${this.query}&strDescricao=${this.query}`;
      }

      this.loading = true;

      ApiService.get("/artigos/byclient", queryString)
        .then((response) => {
          this.list = response.data.Data;
          this.totalItems = response.data.TotalPages;
          this.pages = Math.ceil(this.totalItems / this.itemsPerPage);
          this.loading = false
        })
        .catch((error) => {
          console.log(error);
          this.loading = false
        });
    },
    updateFetchPage(page, pagesSize, sortByAsc = true) {
      this.page = page;
      this.itemsPerPage = pagesSize;
      this.getProducts(sortByAsc);
    },
    paginatorHandler(page, sortByAsc = true) {
      this.page = page;
      this.getProducts(sortByAsc);
    },
    updateStatus(status) {
      this.status = status;
      this.getProducts();
    },
    queryForProduct(data) {
      this.page = 1;
      this.query = data.searchInput;
      this.getProducts();
    },
    sortBy(orderBy, sortByAsc) {
      this.getProducts(orderBy, sortByAsc);
    },
    downloadPdf(docId) {
      //call api, get file in b64 format, and download it
      ApiService.get("Utils/document/" + docId).then((response) => {
        let file = response.data.Data.docBase64;
        let fileName = response.data.Data.docName;
        const parts = fileName.split(".");
        let fileExtension = parts[parts.length - 1];
        let mimeType = "application/pdf";
        if (fileExtension === "xml") {
          mimeType = "application/xml";
        }
        //if txt
        else if (fileExtension === "txt") {
          mimeType = "text/plain";
        }

        let byteCharacters = atob(file);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        let blob = new Blob([byteArray], { type: mimeType });

        // Create a URL for the Blob
        let fileURL = URL.createObjectURL(blob);

        // Open the file in a new tab
        let newTab = window.open(fileURL, "_blank");
        if (newTab) {
          newTab.document.title = fileName;
        } else {
          // Opening the new tab was blocked by the browser
          console.error("Opening the file was blocked by the browser.");
          window.location.href = fileURL;
        }
      });
    },
  },
  mounted() {
    setCurrentPageBreadcrumbs("Produtos Adquiridos");
    this.getProducts();
    //this.loadFamilies();
  },
};
</script>

<style>
</style>